import * as React from 'react';

import { Link } from 'components/link/Link';

import s from './IssueItem.scss';

interface IIssueItemProps {
  heading: string;
  to: string;
}

export const IssueItem = ({ heading, to }: IIssueItemProps) => (
  <Link className={s.issue} to={to}>
    <h3 className={s.issue__heading}>{heading}</h3>
  </Link>
);
