import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import { Container } from 'components/container/Container';
import { Item } from 'components/item/Item';
import { Hero } from 'components/hero/Hero';
import { IssueItem } from 'components/issue-item/IssueItem';
import { BlockList } from 'components/block-list/BlockList';

export const query = graphql`
  query AuthorContentQuery($id: String) {
    contentfulHofundur (id: { eq: $id }) {
      name
    }
    allContentfulGrein (filter: { author:{ elemMatch: { id: { eq: $id } } } }) {
      edges {
        node {
          title
          slug
        }
      }
    }
    allContentfulHefti (filter: { editors:{ elemMatch: { id: { eq: $id } } } }) {
      edges {
        node {
          volume
          issue
          published
        }
      }
    }
  }
`;

export default ({ data: { allContentfulGrein, allContentfulHefti, contentfulHofundur: { name } } }: any) => (
  <>
    <Helmet title={name} />
    <Hero
      title={name}
      contentHeading={allContentfulGrein ? `${allContentfulGrein.edges.length} greinar eftir höfund` : 'Engin grein eftir höfund'}
      lines={[]}
    />
    {allContentfulGrein && (
      <BlockList
        heading="Greinar"
      >
        {allContentfulGrein.edges.map(({ node: { slug, title } }: any) => (
        <Item
          to={`/grein/${slug}`}
          title={title}
        />
      ))}
      </BlockList>
    )}
    {allContentfulHefti && (
      <BlockList
        heading="Í ritstjórn"
      >
        {allContentfulHefti.edges.map(({ node: { volume, issue, published }}: any) =>
          <IssueItem
            key={`${volume}-${issue}`}
            to={`/hefti/${volume}/${issue}`}
            heading={`${volume}. árgangur, ${issue}. hefti, ${new Date(published).getUTCFullYear()}`}
          />,
        )}
      </BlockList>
    )}
  </>
);
